import { lazy } from 'react';
const ComingSoon = lazy(() =>
  import('pages/genericPages/comingSoon/ComingSoon'),
);
const WITHOUT_IMAGE_VARIANT = 'withoutImage';
// !warning: We will be using this file to add routes specifically for upcoming events will be removed later
export const TempRoutes = [
  {
    type: 'collapse',
    name: 'comingSoonBenefits',
    key: 'comingSoonBenefits',
    module: 'Surface',
    route: '/benefits',
    component: (
      <ComingSoon
        variant={WITHOUT_IMAGE_VARIANT}
        heading={'Flexible Benefits'}
        subHeading={'Give employees the power to choose'}
      />
    ),
  },
  {
    type: 'collapse',
    name: 'comingSoonCommunities',
    key: 'comingSoonCommunities',
    route: '/community',
    module: 'Surface',
    subModule: 'Communities',
    parentId: 'ManageCommunities',
    component: (
      <ComingSoon
        variant={WITHOUT_IMAGE_VARIANT}
        heading={'Connect & Grow Together'}
        subHeading={'Engage with peers and share insights'}
      />
    ),
  },
  {
    type: 'collapse',
    name: 'comingSoonEvent',
    key: 'comingSoonEvent',
    route: '/event',
    module: 'Surface',
    parentId: 'Surface',
    component: (
      <ComingSoon
        variant={''}
        heading={'Effortless events management'}
        subHeading={'Plan & execute company events.'}
      />
    ),
  },
  {
    type: 'collapse',
    name: 'comingSoonSurvey',
    key: 'comingSoonSurvey',
    parentId: 'ManageCreatesurveys',
    module: 'Surface',
    route: '/survey',
    component: (
      <ComingSoon
        variant={WITHOUT_IMAGE_VARIANT}
        heading={'Employee Voice Matters'}
        subHeading={'Active listening and actionable insights.'}
      />
    ),
  },
  {
    type: 'collapse',
    name: 'comingSoonTotalRewards',
    key: 'comingSoonTotalRewards',
    parentId: 'Compensation',
    module: 'Surface',
    route: '/total-rewards',
    component: (
      <ComingSoon
        variant={''}
        heading={'Showcase entire EVP'}
        subHeading={'Showcase full spectrum of Total Rewards to your people.'}
      />
    ),
  },
  {
    type: 'collapse',
    name: 'comingSoonCompensationPlanning',
    key: 'comingSoonCompensationPlanning',
    parentId: 'Compensation',
    module: 'Surface',
    route: '/compensation-planning',
    component: (
      <ComingSoon
        variant={WITHOUT_IMAGE_VARIANT}
        heading={'Data Driven Compensation'}
        subHeading={
          'Tools and insights for creating a fair and competitive pay.'
        }
      />
    ),
  },
  {
    type: 'collapse',
    name: 'comingSoonHiringIntelligence',
    key: 'comingSoonHiringIntelligence',
    module: 'Surface',
    route: '/hiring-intelligence',
    parentId: null,
    component: (
      <ComingSoon
        variant={''}
        heading={'Smart Hiring Decisions'}
        subHeading={
          'Make data driven hiring decisions with real time compensation data.'
        }
      />
    ),
  },
  {
    type: 'collapse',
    name: 'comingSoonEquity',
    key: 'comingSoonEquity',
    parentId: 'Compensation',
    module: 'Surface',
    route: '/equity',
    component: (
      <ComingSoon
        variant={WITHOUT_IMAGE_VARIANT}
        heading={'Fuel Your Future'}
        subHeading={'Manage all things equity in one place'}
      />
    ),
  },
  {
    type: 'collapse',
    name: 'comingSoonPolicies',
    key: 'comingSoonPolicies',
    parentId: 'Policy',
    module: 'Surface',
    route: '/policies',
    component: (
      <ComingSoon
        variant={''}
        heading={'Global Employee Policies'}
        subHeading={'Seamless management of all policies'}
      />
    ),
  },
  {
    type: 'collapse',
    name: 'comingSoonInsurance',
    key: 'comingSoonInsurance',
    module: 'Surface',
    route: '/insurance',
    parentId: 'Admin',
    component: (
      <ComingSoon
        variant={WITHOUT_IMAGE_VARIANT}
        heading={'Insurance Technology'}
        subHeading={'Global standard, modular or flex Insurance'}
      />
    ),
  },
  {
    type: 'collapse',
    name: 'BenchmarkPlans',
    key: 'BenchmarkPlans',
    module: 'Surface',
    route: '/range-builder',
    parentId: 'RangeBuilder',
    component: (
      <ComingSoon
        variant={''}
        heading={'Competitive Market Data'}
        subHeading={
          'Design pay ranges aligned with your strategies & market trends'
        }
      />
    ),
  },
];
