import { rbacFlags } from 'config/rbacFlags';
import { lazy } from 'react';
const ManagePools = lazy(() => import('pages/Esops/ManagePools/ManagePools'));
const ManagePlans = lazy(() => import('pages/Esops/ManagePlan/ManagePlans'));
const CreatePlan = lazy(() =>
  import('pages/Esops/ManagePlan/CreatePlan/CreatePlan'),
);
const ViewPlanData = lazy(() =>
  import('pages/Esops/ManagePlan/ViewPlan/ViewPlanData/ViewPlanData'),
);
const ManageGrantsHeader = lazy(() =>
  import('pages/Esops/grants/ManageGrantsHeader'),
);
const ManageUploadGrants = lazy(() =>
  import('pages/Esops/grants/ManageUploadGrants'),
);
const AdminDashboard = lazy(() =>
  import('pages/Esops/AdminDashboard/AdminDashboard'),
);
const PerformanceBasedRecognition = lazy(() =>
  import('pages/Esops/grants/PerformanceBasedRecognition'),
);

const EsopsRoutes = [
  {
    type: 'collapse',
    name: 'managePools',
    key: 'managePools',
    parentId: 'Esops',
    route: '/esops/ManagePools',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ManagePools />,
  },
  {
    type: 'collapse',
    name: 'managePlans',
    key: 'managePlans',
    parentId: 'Esops',
    route: '/esops/ManagePlans',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ManagePlans />,
  },
  {
    type: 'collapse',
    name: 'esopCreatePlan',
    key: 'esopCreatePlan',
    parentId: 'Esops',
    route: '/esops/createPlan',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <CreatePlan />,
  },
  {
    type: 'collapse',
    name: 'esopViewPlanData',
    key: 'esopViewPlanData',
    parentId: 'Esops',
    route: '/esops/viewPlan',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ViewPlanData />,
  },
  {
    type: 'collapse',
    name: 'esopsAdminDashboard',
    key: 'esopsAdminDashboard',
    parentId: 'Esops',
    route: '/esops/adminDashboard',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <AdminDashboard />,
  },
  {
    type: 'collapse',
    name: 'EsopsGrants',
    key: 'EsopsGrants',
    parentId: 'Esops',
    route: '/esops/grants',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ManageGrantsHeader />,
  },
  {
    type: 'collapse',
    name: 'ManageUploadGrants',
    key: 'manageUploadGrants',
    parentId: 'Esops',
    route: '/esops/grants/upload-data/:id',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <ManageUploadGrants />,
  },
  {
    type: 'collapse',
    name: 'GrantsPerformanceBasedRecognition',
    key: 'grantsPerformanceBasedRecognition',
    parentId: 'Esops',
    route: '/esops/grants/performance-based-recognition',
    // flagName: rbacFlags.Provideaccesstocreateandeditreports,
    module: 'Admin',
    component: <PerformanceBasedRecognition />,
  },
];

export default EsopsRoutes;
