import { VariablePay } from 'pages/variablePay/VariablePay';

const VariablePayRoutes = [
  {
    type: 'collapse',
    name: 'variable-pay',
    key: 'variable-pay',
    route: '/variable-pay/plans',
    parentId: 'Task',
    module: 'Task',
    component: <VariablePay />,
  },
];

export default VariablePayRoutes;
