import PlusIconGradient from 'assets/svg/plusIconGradient';
import Button from 'components/atoms/button/Button';
import DatePicker from 'components/atoms/FormElements/datepicker/DatePicker';
import { Input, RadioButton } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import ModalPopup from 'components/layout/modalPopup/ModalPopup';
import { Dispatch, SetStateAction, useState } from 'react';

type CreatePlanPopUpProps = {
  setCreatePlanPopUpOpen: Dispatch<SetStateAction<boolean>>;
};

export const CreatePlanPopUp = ({
  setCreatePlanPopUpOpen,
}: CreatePlanPopUpProps) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [planType, setPlanType] = useState<string>('Event(s)');

  return (
    <ModalPopup
      onClose={() => setCreatePlanPopUpOpen(false)}
      title={
        <div className="gradient-border-and-no-text mx-2 w-12 h-12 before:border-[1.5px] before:rounded-[10px] flex items-center justify-center">
          <PlusIconGradient />
        </div>
      }
      body={
        <div>
          <div className="px-6 space-y-5 pb-5">
            <div className="space-y-1">
              <p className="text-lg font-medium text-gray-900">Create New</p>
              <p className="text-sm text-gray-600">Create a new Bonus Plan</p>
            </div>
            <div className="space-y-4">
              <Input
                // @ts-ignore
                label="Plan Name"
                className="!py-2 text-sm"
                placeholder="Enter plan name"
              />
              <div>
                <Select
                  // @ts-ignore
                  placeholder="Select salary group"
                  label="Salary Group under Total Rewards"
                  options={[]}
                />
              </div>
              <div>
                <DatePicker
                  // @ts-ignore
                  label="Start and End Date"
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(range: Date[]) => {
                    setStartDate(range[0]);
                    setEndDate(range[1]);
                  }}
                />
              </div>
              <div>
                <label className="text-sm my-1 block text-gray-700 font-medium">
                  Bonus Based on
                </label>
                <div className="flex items-center justify-between">
                  <RadioButton
                    // @ts-ignore
                    label="Metric(s)"
                    checked={planType === 'Metric(s)'}
                    onChange={() => setPlanType('Metric(s)')}
                    id="metrics"
                  />
                  <RadioButton
                    // @ts-ignore
                    label="Event(s)"
                    checked={planType === 'Event(s)'}
                    id="events"
                    onChange={() => setPlanType('Event(s)')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-3 px-6 pt-4 bg-white border-t border-gray-200 sticky bottom-0">
            <button
              className="text-sm font-semibold text-gray-600"
              onClick={() => setCreatePlanPopUpOpen(false)}
            >
              Cancel
            </button>
            <Button className="text-sm font-semibold" variant="filled">
              Create
            </Button>
          </div>
        </div>
      }
      height=""
      width="w-[25rem]"
    />
  );
};
