import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import Button from 'components/atoms/button/Button';
import Tabs from 'components/molecules/tabs/Tabs';
import { JSX, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreatePlanPopUp } from './CreatePlanPopUp';

const tabs = [
  { id: 1, tabLabel: 'Plans' },
  { id: 2, tabLabel: 'Employees' },
];

export const VariablePay = (): JSX.Element => {
  const navigate = useNavigate();
  const [createPlanPopUpOpen, setCreatePlanPopUpOpen] =
    useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>('Plans');

  return (
    <div className="pt-6 px-8 bg-white h-full">
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex flex-row items-center gap-3 justify-start">
            <div
              className="p-2 rounded-lg border-2 cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftIcon className="w-5 h-5" />
            </div>
            <div className=" flex items-center justify center text-2xl font-semibold">
              Manage Variable Pay
            </div>
          </div>
          <div className="flex items-center gap-3">
            <Button className="text-sm font-semibold" variant="outlined">
              Dashboard
            </Button>
            <Button
              variant="filled"
              className="text-sm font-semibold"
              onClick={() => setCreatePlanPopUpOpen(true)}
            >
              Create New
            </Button>
          </div>
        </div>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          handleTab={(e: { tabLabel: SetStateAction<string> }) =>
            setSelectedTab(e.tabLabel)
          }
        />
      </div>
      {createPlanPopUpOpen && (
        <CreatePlanPopUp setCreatePlanPopUpOpen={setCreatePlanPopUpOpen} />
      )}
    </div>
  );
};
